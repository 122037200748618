import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { List } from './components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "before-you-begin"
    }}>{`Before you begin`}</h1>
    <p>{`Before you register for a developer account and start coding, thinking through a few questions will help you select the most appropriate tools from the SmartVault API for your situation.
Specifically, this process will define the authentication scheme, the interaction method, and the integration model that is best suited to your needs.`}</p>
    <br />
    <h2 {...{
      "id": "authentication-what-kind-of-application-are-you-building"
    }}>{`Authentication: What Kind of Application Are You Building?`}</h2>
    <br />
    <p><strong parentName="p">{`Autonomous Authentication`}</strong></p>
    <hr></hr>
    <p>{`If you’re building a server-to-server application, you will want to use Autonomous Authentication which is based on RSA keypairs. In general, Autonomous Authentication is suitable in situations where you can completely control a private key.`}<br /><br />{`
Using this approach, your application will authenticate to SmartVault using public/private keys, and all communication will be done over HTTPS using TLS 1.3.`}</p>
    <br />
    <br />
    <p><strong parentName="p">{`PIN-Based Authentication`}</strong></p>
    <hr></hr>
    <p>{`If you’re building a customer-facing web, rich, or mobile app, you will want to use PIN-Based Authentication.
Using this technique, the user inputs a PIN code into your application.
The mechanism for generating and capturing this PIN code varies based on your application’s ability to handle a callback from the SmartVault web server:`}</p>
    <List values={[{
      title: 'Direct Entry',
      value: 'With a rich application (e.g., a command-line or WinForms application), registering a callback can be difficult or impossible.  In these situations, your application will make an API call to request a PIN on the user’s behalf.  The SmartVault server will reply with a URI that the user can follow to retrieve a PIN, which is in turn manually entered into your application. The PIN is then used to authenticate each API call for that user.'
    }, {
      title: 'Redirect URI',
      value: 'With a web or mobile application, you can register redirect URI (a callback) with the SmartVault API, and then use standard OAuth to have your application obtain the PIN.  The user will be prompted to Allow or Deny your application to access his or her information, but is not required to manually enter any PIN code as in the "Direct Entry" approach.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "interaction-what-language-are-you-writing-your-application-in"
    }}>{`Interaction: What Language Are You Writing Your Application in?`}</h2>
    <br />
    <List values={[{
      title: 'The SmartVault REST API can be called directly and supports data exchange using JSON and XML. This approach is ideal for web applications.'
    }, {
      title: 'We also have C# and Java wrapper libraries that can be downloaded here.  The libraries use a binary transfer protocol using Google’s Protocol Buffers and make it easy to write applications in these languages by removing the need to create and parse API requests and replies.  These libraries are ideal for rich applications as well as mobile applications, although such applications can certainly use the Direct method as well.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <h2 {...{
      "id": "integration-how-do-you-want-to-use-the-smartvault-service"
    }}>{`Integration: How Do You Want to Use the SmartVault Service?`}</h2>
    <br />
    <List values={[{
      title: 'Generic Model',
      value: 'This model is best viewed as a hard drive in the cloud.  In this model, the SmartVault API allows you to CRUD folders and documents, invite users, and manipulate access control on the objects in the cloud.  This approach is best when you need flexibility and want to manage provisioning and maintaining folder structures, determine where to store and find documents, and maintain the mapping between objects and documents.'
    }, {
      title: 'Application Model',
      value: 'In this model, you provide SmartVault a pre-defined mapping of your objects to a folder structure, and SmartVault will create and maintain that mapping for you. This model reduces some flexibility, but simplifies provisioning and maintaining folder structures and object-document mapping.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      